import React from 'react'
import EventCard from '../components/Cards/EventCard'
import CorporateEventCard from '../components/Cards/CorporateEventCard'
import Footer from '../components/Shared/Footer'

const CorporateEvents = () => {
    const events=[{
      url:'conferenceHall.avif',
      icon:'🏖️',
      date:'July 15, 2023',
      title:'Breathtaking Destinations',
      description:'Immerse your team in awe-inspiring locations that spark creativity and foster connection. From secluded beaches to mountain retreats, we curate environments that inspire greatness.',
      link:'https://events.thevillalife.com/events/luxury-rental-summit-2023'
    },
    {
      url:'gathering.avif',
      icon:'🎉',
      date:'August 19, 2023',
      title:'Unforgettable Experiences',
      description:'Elevate your corporate retreat with unforgettable experiences that bring your team closer together. From private chef dinners to luxury yacht charters, we create moments that last a lifetime.',
      link:'https://events.thevillalife.com/events/villalife-pool-party'
    },
    {
      url:'gathering1.avif',
      icon:'🌟',
      date:'September 23, 2023',
      title:'Luxury Accommodations',
      description:'Experience the height of luxury with our handpicked selection of villas, mansions, and estates. Each property is designed to provide the ultimate in comfort, privacy, and relaxation.',
      link:'https://events.thevillalife.com/events/beach-retreat-weekend'
    },
    {
      url:'gathering2.avif',
      icon:'🍸',
      date:'December 15, 2023',
      title:'World-Class Dining',
      description:'Indulge in gourmet meals prepared by our team of private chefs. From breakfast buffets to multi-course dinners, we offer a range of dining options to suit every taste and preference.',
      link:'https://events.thevillalife.com/events/holiday-gala-dinner'
    },
    {
      url:'presentation.avif',
      icon:'🎤',
      date:'July 15, 2023',
      title:'Expert Speakers',
      description:'Learn from industry experts, thought leaders, and innovators who share their knowledge and insights on a wide range of topics. Our speakers inspire, educate, and motivate your team to succeed.',
      link:'https://events.thevillalife.com/events/luxury-rental-summit-2023'
    },
    {
      url:'presentation1.avif',
      icon:'🎶',
      date:'August 19, 2023',
      title:'Team-Building Activities',
      description:'Strengthen your team with engaging and interactive team-building activities. From scavenger hunts to cooking classes, we offer a variety of activities that promote collaboration and communication.',
      link:'https://events.thevillalife.com/events/villalife-pool-party'
    },
    {
      url:'presentation2.avif',
        icon:'🏄',
        date:'September 23, 2023',
        title:'Wellness & Relaxation',
        description:'Prioritize your team\'s well-being with wellness and relaxation activities. From yoga classes to spa treatments, we offer a range of options to help your team unwind and recharge.',
        link:'https://events.thevillalife.com/events/beach-retreat-weekend'
    },
    {
      url:'holiday-gala.avif',
      icon:'🎄',
      date:'December 15, 2023',
      title:'Holiday Celebrations',
      description:'Celebrate the holiday season with festive events and activities. From themed parties to gift exchanges, we create memorable experiences that bring joy and cheer to your team.',
      link:'https://events.thevillalife.com/events/holiday-gala-dinner'
    },
    {
      url:'conferenceHall.avif',
      icon:'🏖️',
      date:'July 15, 2023',
      title:'Breathtaking Destinations',
      description:'Immerse your team in awe-inspiring locations that spark creativity and foster connection. From secluded beaches to mountain retreats, we curate environments that inspire greatness.',
      link:'https://events.thevillalife.com/events/luxury-rental-summit-2023'
    }
  
  ]
    return (
      <body>
    <main>
      <section className="hero-event">
        <div className="hero-content">
          {/* <div className=' text-6xl font-semibold tracking-wide'>Thevillalife Events</div> */}
          <h1>Elevate Your Corporate Retreat Experience</h1>
          <p>Immerse your team in luxury, inspiration, and unparalleled team-building opportunities</p>
          <a href="/contact" class="cta-button">Start Planning Your Retreat</a>
        </div>
      </section>
      
      <section className="events-section">
        <div className="container">
          <div className="events-grid">
            {events.map((event, index) => (
              <CorporateEventCard key={index} {...event} />
            ))}
          </div>
        </div>
      </section>
      <section class="testimonial-section">
      <div class="container">
        <h2 class="section-title">What Our Clients Say</h2>
        <div class="testimonial-container">
          <div class="testimonial-card">
            <p class="testimonial-text">"TheVillaLife transformed our annual retreat into an unforgettable journey of growth and connection. The attention to detail and personalized experiences exceeded all expectations."</p>
            <p class="testimonial-author">- Sarah Johnson, CEO of InnovateTech</p>
          </div>
          <div class="testimonial-card">
            <p class="testimonial-text">"The team-building activities were not just fun, but truly impactful. We returned to the office with renewed energy and a stronger sense of unity."</p>
            <p class="testimonial-author">- Michael Chen, Director of Human Resources at GlobalCorp</p>
          </div>
        </div>
      </div>
    </section>

    <section class="cta-section">
      <div class="container">
        <h2 class="cta-title">Ready to Elevate Your Team's Experience?</h2>
        <p class="cta-description">Let us craft a bespoke retreat that aligns with your company's vision and goals.</p>
        <a href="/contact" class="cta-button-cr">Start Planning Your Retreat</a>
      </div>
    </section>
    </main>
    
    <Footer />
</body>
    )
  }
export default CorporateEvents