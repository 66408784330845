import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import FakeData from '../villas_all'
import RentalCard from '../components/Cards/RentalCard'
const EventDetails = () => {
  const { slug } = useParams();
  var loca = "Barbados"
  if(slug === "barbados"){ 
    loca= "Barbados"
  }
  if(slug === "saint_martin"){
    loca= "Saint Martin (French part)" || "Sint Maarten (Dutch part)"
  }
  // if(slug === "saint_martin"){
  //   loca= "Sint Maarten (Dutch part)"
  // }
  if(slug === "mexico"){
    loca= "Mexico"
  }
  if(slug === "saint_barts"){
    loca= "Saint Barthélemy"
  }
      
  const [location, setLocation] = React.useState(loca)
  const [data, setData] = React.useState(FakeData.result.filter(villa => (location === "" || villa.listing.countryUsingCountryCode === location) ))
  const [bedRooms, setBedRooms] = React.useState(4)
  const [price, setPrice] = React.useState(5000)
  
  const [guests, setGuests] = React.useState("")
  const [count, setCount] = React.useState(data.length)
  const applyFilters = () => {
    setData(FakeData.result.filter(villa => (villa.listing.countryUsingCountryCode === location) &&
      (guests === "" || villa.listing.personCapacity >= guests) &&
      (price === 20000 || villa.listing.price <= price) &&
      (bedRooms === 1 || villa.listing.bedsNumber >= bedRooms)
    ))
  }
  useEffect(() => {
    setCount(data.length)
  },[data.length])

  
  return (
    <body>
     <main>
    <section class="hero">
      <div class="hero-content">
        <h1>Luxury Rentals in {loca}</h1>
        <p>Experience the beauty and tranquility of {loca} in our handpicked luxury villas and estates</p>
        <div class="search-bar">
          <button onClick={()=>{
              var element = document.getElementById("filter-section");
              element.scrollIntoView({behavior: "smooth"});}
            }>Explore Now
          </button>
        </div>
      </div>
    </section>
    
    <section class="filter-section" id="filter-section">
      <div class="container">
        <div class="filter-container">
          {/* <div class="filter-group">
            <label for="location">Location</label>
            <select id="location">
              <option value="">All Locations</option>
              <option value="west-coast">West Coast</option>
              <option value="south-coast">South Coast</option>
              <option value="east-coast">East Coast</option>
              <option value="inland">Inland</option>
            </select>
          </div> */}
          <div class="filter-group">
            <label for="guests">Guests</label>
            <select id="guests" onChange={e=>setGuests(e.target.value)}>
              <option value="">Any</option>
              <option value="2">1-2</option>
              <option value="4">3-4</option>
              <option value="6">5-6</option>
              <option value="7+">7+</option>
            </select>
          </div>
          <div class="filter-group">
            <label for="price-range">Price Range</label>
            <div class="range-slider">
              <input type="range" id="price-range" min="300" max="20000" step="100" value={price} onChange={e=>setPrice(e.target.value)}/>
              <span id="price-value">${price}</span>
            </div>
          </div>
          <div class="filter-group">
            <label for="bedrooms">Bedrooms</label>
            <div class="range-slider">
              <input type="range" id="bedrooms" min="1" max="10" step="1" value={bedRooms} onChange={e=>setBedRooms(e.target.value)}/>
              <span id="bedroom-value">{bedRooms}</span>
            </div>
          </div>
          <button class="filter-button" onClick={()=>applyFilters()}>Apply Filters</button>
        </div>
        <div class="advanced-filters">
          {/* <h3>Advanced Filters</h3> */}
          <div class="checkbox-group">
              {/* <label>Advanced Filters:</label>
              <label><input type="checkbox" id="pool"/> Pool</label>
              <label><input type="checkbox" id="beachfront"/> Beachfront</label>
              <label><input type="checkbox" id="wifi"/> Wi-Fi</label>
              <label><input type="checkbox" id="parking"/> Parking</label> */}
              <label>Total Count:{count}</label>
          </div>
        </div>
      </div>
    </section>
    
    <section id="rentals" class="rentals-section">
      <div class="container">
        <div class="rentals-grid">
        {data && data.map((villa,idx) => (
            <RentalCard data={villa} key={villa.id}/>
          ))}

        </div>
      </div>
    </section>

    {/* <section class="about-barbados">
      <div class="container">
        <div class="about-content">
          <div class="about-text">
            <h2>Discover Barbados</h2>
            <p>Barbados, the easternmost island in the Caribbean, is a tropical paradise known for its pristine beaches, crystal-clear waters, and warm hospitality. This coral island boasts a rich history, vibrant culture, and diverse landscapes that range from rolling hills to dramatic cliffs.</p>
            <p>Visitors to Barbados can indulge in world-class cuisine, explore historic sites, enjoy water sports, or simply relax on the sun-kissed beaches. The island's west coast, often referred to as the "Platinum Coast," is home to many luxury resorts and villas, while the rugged east coast offers breathtaking views and surfing spots.</p>
            <p>Whether you're seeking a romantic getaway, a family vacation, or an adventure-filled trip, Barbados offers something for everyone. Experience the charm of this Caribbean gem with our curated selection of luxury rentals.</p>
          </div>
          <div class="about-image">
            <img src="https://images.unsplash.com/photo-1626094309830-abbb0c99da4a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2232&q=80" alt="Beautiful beach in Barbados" width="600" height="400" />
          </div>
        </div>
      </div>
    </section>

    <section class="testimonials">
      <div class="container">
        <h2>What Our Guests Say</h2>
        <div class="testimonial-grid">
          <div class="testimonial-card">
            <p class="testimonial-text">"Our stay at the Sandy Lane Oasis was nothing short of spectacular. The villa was luxurious, the staff attentive, and the views breathtaking. We can't wait to return!"</p>
            <p class="testimonial-author">- Sarah and John, New York</p>
          </div>
          <div class="testimonial-card">
            <p class="testimonial-text">"The Crane Beach Retreat exceeded all our expectations. It was the perfect blend of privacy and accessibility to local attractions. A true gem in Barbados!"</p>
            <p class="testimonial-author">- The Thompson Family, London</p>
          </div>
          <div class="testimonial-card">
            <p class="testimonial-text">"From the moment we arrived at the Platinum Coast Estate, we felt like royalty. The amenities, the service, and the location were all top-notch. It was the vacation of a lifetime."</p>
            <p class="testimonial-author">- Michael and Emma, Toronto</p>
          </div>
        </div>
      </div>
    </section> */}
  </main>
  <footer className="footer">
  <div className="container">
    <p>&copy; 2024 Thevillalife. All rights reserved.</p>
    <p>
      <a href="/privacy">Privacy Policy</a> | 
      <a href="/terms">Terms of Service</a> |
      <a href="/contact">Contact Us</a>
    </p>
  </div>
</footer>
</body>
  )
}

export default EventDetails