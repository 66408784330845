import React, { useEffect } from 'react'
import Footer from '../components/Shared/Footer'
// import FakeData from '../villas'
import FakeData from '../villas_all'
import RentalCard from '../components/Cards/RentalCard'
const Events = () => {
  // const [data, setData] = React.useState(FakeData)
  const [data, setData] = React.useState(FakeData.result.slice(0,30))
  const [bedRooms, setBedRooms] = React.useState(4)
  const [price, setPrice] = React.useState(5000)
  const [location, setLocation] = React.useState("")
  const [guests, setGuests] = React.useState("")
  const [count, setCount] = React.useState(data.length)
  const applyFilters = () => {
    setData(FakeData.result.filter(villa => (location === "" || villa.listing.countryUsingCountryCode === location) && 
      (guests === "" || villa.listing.personCapacity >= guests) &&
      (price === 20000 || villa.listing.price <= price) &&
      (bedRooms === 1 || villa.listing.bedsNumber >= bedRooms)
    ))
  }
  useEffect(() => {
    setCount(data.length)
  },[data.length])
  // useEffect(() => {
  //   if(location !== ""){
  //     setData(FakeData.filter(villa => villa.listing.timeZoneName === location))
  //   }
  //   if(guests !== ""){
  //     setData(FakeData.filter(villa => villa.listing.personCapacity >= guests))
  //   }
  //   if(price < 20000){
  //     setData(FakeData.filter(villa => villa.listing.price <= price))
  //   }
  //   if(bedRooms > 1){
  //     setData(FakeData.filter(villa => villa.listing.bedsNumber >= bedRooms))
  //   }
  //   setCount(data.length)
  // }
  // ,[location, guests, price, bedRooms, data.length])

  return (
    <body>
  <main>
    <section class="hero">
      <div class="hero-content">
        <h1>Unforgettable Luxury Escapes</h1>
        <p>Handpicked properties in the world's most stunning locations</p>
        <div class="search-bar">
          {/* <input type="text" placeholder="Where do you want to go?"/> */}
          <button onClick={()=>{
              var element = document.getElementById("filter-section");
              element.scrollIntoView({behavior: "smooth"});}
            }>Explore Now
          </button>
        </div>
      </div>
    </section>
    
    <section class="filter-section" id="filter-section">
      <div class="container">
        <div class="filter-container">
          <div class="filter-group">
            <label for="location">Location</label>
            <select id="location"  onChange={e=>setLocation(e.target.value)}>
              <option value="">All Locations</option>
              <option value="Barbados">Barbados</option>
              <option value="Saint Martin (French part)">Saint Martin - French Part</option>
              <option value="Sint Maarten (Dutch part)">Saint Martin - Dutch Part</option>
              <option value="Mexico">Mexico</option>
              <option value="Saint Barthélemy">Saint Barths</option>
            </select>
          </div>
          {/* <div class="filter-group">
            <label for="dates">Dates</label>
            <input type="text" id="dates" placeholder="Select dates"/>
          </div> */}
          <div class="filter-group">
            <label for="guests">Guests</label>
            <select id="guests" onChange={e=>setGuests(e.target.value)}>
              <option value="">Any</option>
              <option value="2">1-2</option>
              <option value="4">3-4</option>
              <option value="6">5-6</option>
              <option value="7+">7+</option>
            </select>
          </div>
          <div class="filter-group">
            <label for="price-range">Price Range</label>
            <div class="range-slider">
              <input type="range" id="price-range" min="300" max="20000" step="100" value={price} onChange={e=>setPrice(e.target.value)}/>
              <span id="price-value">${price}</span>
            </div>
          </div>
          <div class="filter-group">
            <label for="bedrooms">Bedrooms</label>
            <div class="range-slider">
              <input type="range" id="bedrooms" min="1" max="10" step="1" value={bedRooms} onChange={e=>setBedRooms(e.target.value)}/>
              <span id="bedroom-value">{bedRooms}</span>
            </div>
          </div>
          <button class="filter-button" onClick={()=>applyFilters()}>Apply Filters</button>
        </div>
        <div class="advanced-filters">
          {/* <h3>Advanced Filters</h3> */}
          <div class="checkbox-group">
              {/* <label>Advanced Filters:</label>
              <label><input type="checkbox" id="pool"/> Pool</label>
              <label><input type="checkbox" id="beachfront"/> Beachfront</label>
              <label><input type="checkbox" id="wifi"/> Wi-Fi</label>
              <label><input type="checkbox" id="parking"/> Parking</label> */}
              <label>Total Count:{count}</label>
          </div>
          
        </div>
      </div>
    </section>

    <section class="rentals-section">
      <div class="container">
        <div class="rentals-grid">
          {/* <div class="rental-card">
            <div class="rental-image-container">
              <img class="rental-image" src="https://images.unsplash.com/photo-1499793983690-e29da59ef1c2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"  alt="Oceanfront Paradise Villa in Barbados" />
              <div class="rental-price">$5,000<span>/night</span></div>
            </div>
            <div class="rental-content">
              <h2 class="rental-title">Oceanfront Paradise Villa</h2>
              <div class="rental-location">Barbados</div>
              <div class="rental-details">
                <span><i class="fas fa-bed"></i> 4 Bedrooms</span>
                <span><i class="fas fa-bath"></i> 3 Bathrooms</span>
                <span><i class="fas fa-users"></i> Up to 8 guests</span>
              </div>
              <div class="rental-amenities">
                <span><i class="fas fa-wifi"></i> Wi-Fi</span>
                <span><i class="fas fa-swimming-pool"></i> Pool</span>
                <span><i class="fas fa-car"></i> Parking</span>
              </div>
              <a href="https://radhil.com/rentals/oceanfront-paradise-villa" class="rental-link">View Details</a>
            </div>
          </div> */}
          {data && data.map((villa,idx) => (
            <RentalCard data={villa} key={villa.id}/>
          ))}

          
        </div>
      </div>
    </section>

    <section class="popular-links">
      <div class="container">
        <h2>Explore More</h2>
        <div class="link-grid">
          <div class="link-category">
            <h3>Popular Locations</h3>
            <ul>
              <li><a href="https://rent.thevillalife.com/places/barbados">Barbados Villas</a></li>
              <li><a href="https://rent.thevillalife.com/places/saint_martin">Saint Martin Retreats</a></li>
              <li><a href="https://rent.thevillalife.com/places/mexico">Mexico Luxury Homes</a></li>
              <li><a href="https://rent.thevillalife.com/places/saint_barts">Saint Barths Escapes</a></li>
            </ul>
          </div>
          <div class="link-category">
            <h3>Scenic Views</h3>
            <ul>
              <li><a href="#">Beachfront Properties</a></li>
              <li><a href="#">Ocean View Villas</a></li>
              <li><a href="#">Mountain Retreats</a></li>
              <li><a href="#">Private Island Rentals</a></li>
            </ul>
          </div>
          <div class="link-category">
            <h3>Experiences</h3>
            <ul>
              <li><a href="#">Water Sports</a></li>
              <li><a href="#">Culinary Delights</a></li>
              <li><a href="#">Wellness Retreats</a></li>
              <li><a href="#">Cultural Tours</a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</body>
  )
}

export default Events