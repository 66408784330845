import React from 'react'

const RentalCard = (data) => {
  return (
    <>
     <div class="rental-card">
    <div class="rental-image-container">
      <img class="rental-image" src={data.data.listingImage[0]?.url}  alt={data.data.listingImage[0]?.bookingEngineCaption} />
      <div class="rental-price">$ {data.data.listing.price}<span>/night</span></div>
    </div>
    <div class="rental-content">
      <h2 class="rental-title">{data.data.listing.name.split("·")[0]} </h2>
      <div class="rental-location">{data.data.listing.countryUsingCountryCode}</div>
      <div class="rental-details">
        <span><i class="fas fa-bed"></i> {data.data.listing.bedsNumber} Bedrooms</span>
        <span><i class="fas fa-bath"></i> {data.data.listing.bathroomsNumber} Bathrooms</span>
        <span><i class="fas fa-users"></i> Up to {data.data.listing.personCapacity} guests</span>
      </div>
      <div class="rental-amenities">
        <span><i class="fas fa-wifi"></i> Wi-Fi</span>
        <span><i class="fas fa-swimming-pool"></i> Pool</span>
        <span><i class="fas fa-car"></i> Parking</span>
      </div>
      <a href={"https://www.thevillalife.com/listings/"+data.data.id} class="rental-link" target="_blank" rel="noopener noreferrer">View Details</a>
    </div>
  </div>
  </>
  )
}

export default RentalCard